/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

ion-input {
  --highlight-color: unset !important;
}

.toast {
  text-align: center !important;
  --color: var(--ion-color-primary);
  --background: rgb(
    227,
    227,
    225
  ); // warn: with opacity the background is always transparent on iOS
  --border-style: solid;
  --border-width: 1px;
  --border-color: var(--ion-color-primary);
  --border-radius: 16px;
  --box-shadow: 6px 6px 6px rgb(0, 0, 0, 0.5) !important;
}

.content-selection-modal,
.content-selection-modal-high {
  --height: 50%;
  --width: 600px;
  // position: absolute;
  // display: block;
  --border-color: var(--ion-color-medium);
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

.content-selection-modal-high {
  --height: 80% !important;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

.large-modal {
  --height: 90% !important;
  --width: 70% !important;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}
@media screen and (max-width: 600px) {
  .large-modal {
    --height: 100% !important;
    --width: 100% !important;
  }
}

ion-popover.add-content-popover {
  --background: transparent;
  --width: 183px;
}

ion-popover.wide-popover {
  --width: 400px !important;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

.pdf-view-modal {
  --height: 80% !important;
  --width: 90% !important;
  --border-color: var(--ion-color-primary);
  --border-style: solid;
  --border-width: 1px;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

.small-modal {
  --height: 150px !important;
  --width: 250px !important;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

.input-modal {
  --height: 160px !important;
  --width: 90% !important;
  --max-width: 500px;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
}

// ion-modal window able to adjust its height to its content
ion-modal.gdpr-modal-banner {
  align-items: flex-end;
  --height: auto;
  --width: 100%;
  border-top: 4px solid #000;
  --box-shadow: 0px -5px 15px var(--ion-color-medium-shade) !important;
  --backdrop-opacity: 0.5 !important;
}
ion-modal.gdpr-modal-banner .ion-page {
  position: relative;
  display: block;
  contain: content;
}
ion-modal.gdpr-modal-banner .ion-page .inner-content {
  --max-height: 80vh;
  overflow: auto;
}
//

@media screen and (max-width: 1200px) and (min-width: 993px) {
  .content-selection-modal,
  .content-selection-modal-high {
    --width: 50%;
  }
}

@media screen and (max-width: 992px) and (min-width: 767px) {
  .content-selection-modal,
  .content-selection-modal-high {
    --width: 60%;
  }
}

@media screen and (max-width: 768px) and (min-width: 577px) {
  .content-selection-modal,
  .content-selection-modal-high {
    --width: 75%;
  }
}

@media screen and (max-width: 576px) {
  .content-selection-modal,
  .content-selection-modal-high {
    --width: 90%;
  }
}

ion-modal.auto-height {
  &.bottom {
    align-items: flex-end;
  }
  --height: auto;
  --border-radius: 16px;
  --box-shadow: 5px 5px 15px var(--ion-color-medium-shade);
  .ion-page {
    position: relative;
    display: block;
    contain: content;
    .inner-content {
      max-height: 80vh;
      overflow: auto;
      padding: 10px;
    }
  }
}

.alert-narrow .alert-wrapper {
  --width: 320px;
  --max-width: 380px;
  --background: rgb(
    247,
    247,
    245
  ); // warn: with opacity the background is always transparent on iOS
}

.alert-wide {
  white-space: pre-line;
  --width: 500px;
  --max-width: 500px;
  --background: rgb(
    247,
    247,
    245
  ); // warn: with opacity the background is always transparent on iOS
}

.icc-button {
  text-transform: none;
  font-weight: 400;
  --padding-start: 16px;
  --padding-end: 16px;
  --border-radius: 8px 8px 8px 8px;
  --border-color: var(--ion-color-primary);
  --border-width: 1px;
  --border-style: solid;
  letter-spacing: normal;
  fa-icon {
    padding-right: 10px;
  }
}
.icc-button-primary {
  @extend .icc-button;
  --color: var(--ion-color-primary-contrast);
  --color-hover: var(--ion-color-dark-contrast);
  --background: var(--ion-color-primary);
  --background-hover: var(--ion-color-dark);
  --background-hover-opacity: 1;
}
.icc-button-secondary {
  @extend .icc-button;
  --color: var(--ion-color-primary);
  --color-hover: var(--ion-color-dark-contrast);
  --background: transparent;
  --background-hover: var(--ion-color-dark);
  --background-hover-opacity: 1;
}

// // workaround for ion-select bug (margin adds unnecessary scrollbar to ion-select popover)
// // https://github.com/ionic-team/ionic-framework/issues/23971
// .sc-ion-select-popover {
//   padding: 0 !important;
//   margin: 0 !important;
// }
// // remove border line below last item of ion-select popover
// .sc-ion-select-popover:last-child {
//   --border-style: none !important;
// }

// Joyride (application tour) styles
.joyride-step__container {
  box-sizing: border-box;
  position: relative;
  color: #000;
  background-color: var(--ion-color-light);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0 0 30px 1px #000;
  border-radius: 16px 16px 16px 16px;
}

.joyride-step__body {
  color: var(--ion-color-primary);
  text-align: left;
  padding: 0px 6px 10px 6px !important;
  white-space: pre-line;
}
